import React from "react";
import styles from "./navbar.module.scss"

const Navbar = () => {
  return (
    <div className={styles.container}>
      <div className={styles.name}>
        <img src="./svg/nolad_logo.svg" alt="name" />
      </div>

      <div className={styles.nav}>
        <div className={styles.list}>
          <li className={styles.home}>
            <a href="/"> Home</a>
          </li>
          <li className={styles.contact}>
            <a
              href="https://docs.google.com/forms/d/1lzy9P86zoxvwNESEJ9Y1q4hAqIfToZ2hLnVjOqEay9c/viewform?edit_requested=true"
              target="_blank"
              rel="noreferrer"
            >
              Contact
            </a>
          </li>
        </div>

        <div className={styles.logo}>
          <img src="./svg/logo.svg" alt="logo" />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
