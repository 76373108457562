import React from "react";
import styles from "./contact.module.scss";
import Button from "../Button/Button";
// import Footer from "../Footer/Footer";

const Contact = () => {
  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <div className={styles.contact_bg}>
          <img src="./svg/leaf-bg.svg" alt="img" />
        </div>
        <div className={styles.content}>
          <p className={styles.title}>
            Do not miss out on the <span>OPPORTUNITY</span> to scale your{" "}
            <span>BUSINESS!</span>
          </p>
          <p className={styles.subtext}>Get on the waiting list!</p>
          <div className={styles.input}>
            <Button />
          </div>
        </div>
      </div>

      <div className={styles.footer}>
        <div className={styles.logo}>
          <img src="./svg/logo-footer.svg" alt="logo" />
        </div>

        <div className={styles.footer_nav}>
          <div className={styles.nav_content}>
            <div className={styles.details}>
              <span className={styles.img}>
                <img src="./svg/material.svg" alt="contact-card" />
              </span>
              <span className={styles.text}>contact@noladhealth.com</span>
            </div>

            <div className={styles.details}>
              <span className={styles.img}>
                <img src="./svg/terminal.svg" alt="terms-logo" />
              </span>
              <span className={styles.text} id="terms">Terms and Conditions</span>
            </div>
            <div className={styles.details}>
              <span lassName={styles.img}>
                <img src="./svg/Vector.svg" alt="location" />
              </span>
              <span className={styles.text}>Ikeja,lagos</span>
            </div>
          </div>
        </div>

        <div className={styles.divider}></div>

        <div className={styles.social}>
          <div className={styles.social_container}>
            <a href="https://www.linkedin.com/company/nolad-health/">
              {" "}
              <img src="./svg/Facebook.svg" alt="facebook" />
            </a>
          </div>

          <div className={styles.social_container}>
            <a href="https://www.linkedin.com/company/nolad-health/">
              {" "}
              <img src="./svg/Instagram.svg" alt="twitter" />
            </a>
          </div>

          <div className={styles.social_container}>
            <a href="https://www.linkedin.com/company/nolad-health/">
              {" "}
              <img src="./svg/Twitter.svg" alt="twitter" />
            </a>
          </div>

          <div className={styles.social_container}>
            <a
              href="https://www.linkedin.com/company/nolad-health/"
              alt="linkednin"
            >
              {" "}
              <img src="./svg/Linkendin.svg" alt="linkedin" />
            </a>
          </div>
        </div>

        <div>
          <p className={styles.year}>
            &copy; 2023 Nolad Health,All Rights Reserved{" "}
          </p>
        </div>
      </div>
    </section>
  );
};

export default Contact;
