import React from "react";
import { Carousel } from "antd";
import BannerTwo from "../BannerTwo/BannerTwo";
import BannerThree from "../BannerThree/BannerThree";
import BannerOne from "../BannerOne/BannerOne";



//   );
  const Banner = () => {
  return (
    <Carousel effect="fade" autoplay>
      <>
        <BannerOne />
      </>

      <>
        <BannerTwo />
      </>

      <>
        {" "}
        <BannerThree />
      </>
    </Carousel> 
  );

};


export default Banner;


