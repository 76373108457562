import React from "react";
import styles from "./button.module.scss";

const Button = () => {
  return (
    <a
      href="https://docs.google.com/forms/d/1lzy9P86zoxvwNESEJ9Y1q4hAqIfToZ2hLnVjOqEay9c/viewform?edit_requested=true"
      target="_blank"
      rel="noreferrer"
      className={styles.btn_link}
    >
      <button className={styles.button}> Join</button>
    </a>
  );
};

export default Button;
